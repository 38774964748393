import React from "react";

// Custom Hooks
import { useSceneBoard } from "../hooks/useBoard";

// Utilities
import { getInitialDim } from "../utilities/getInitialDim";
import { useScenesStore } from "../store/store";

export const PreviewScene = ({
  absoluteScene,
  editScene = false,
  setScene = () => {},
}) => {
  const scene = useScenesStore((state) => state.scene);
  const initialDim = getInitialDim(absoluteScene?.chains);
  useSceneBoard(
    "canvas",
    absoluteScene,
    initialDim,
    editScene,
    scene,
    setScene,
  );

  return (
    <>
      <h1 className="text-center">
        Total Horizontal Distance: {initialDim.x} units
      </h1>
      <h2 className="text-center">
        Scale {absoluteScene?.scale?.x || 1} : {absoluteScene?.scale?.y || 1}
      </h2>
      <div
        id="canvas"
        className="jxgbox relative flex aspect-square w-full items-center justify-center rounded-lg border border-slate-900"
      />
    </>
  );
};
