// Constants
import { BACKGROUND_IMAGES } from "../constants/constants";
import { getDraggedItem, setDraggedItem } from "./draggedItem";
import { pointOptions } from "./pointUtils";

export const drawBackground = (
  board,
  bgImg,
  dim,
  scale,
  editScene,
  scene,
  setScene,
) => {
  const imgUrlList = BACKGROUND_IMAGES.find(
    (img) => img.value === bgImg,
  ).images;

  const totalHorizontalDistance = Math.abs(dim.maxX) + Math.abs(dim.minX);
  const totalVerticalDistance =
    Math.abs(dim.minY) <= 100
      ? (Math.abs(dim.maxY) + Math.abs(dim.minY)) * scale.y
      : Math.abs(dim.maxY) + Math.abs(dim.minY);
  const imgHorizontalDistance = 1.3333333333333333 * totalVerticalDistance;
  const loopCount = Math.ceil(totalHorizontalDistance / imgHorizontalDistance);
  const minY = Math.abs(dim.minY) <= 100 ? dim.minY * scale.y : dim.minY;

  let i = 0;

  for (let j = 0; j < loopCount; j++) {
    if (i >= imgUrlList.length) i = 0;
    const bg = board.create(
      "image",
      [
        imgUrlList[i],
        [
          dim.minX + imgHorizontalDistance * j,
          scene?.bgYPosition
            ? scene?.bgYPosition - totalVerticalDistance / 2
            : minY,
        ],
        [imgHorizontalDistance, totalVerticalDistance],
      ],
      { fixed: true, highlight: false, opacity: 0.2 },
    );
    i++;
  }

  // Background Data Point
  const bgDataPointOptions = {
    name: "Scene Background",
    size: 4,
    fixed: !editScene,
    visible: editScene,
    ...pointOptions,
  };

  const bgDataPoint = board.create(
    "point",
    [
      0,
      scene?.bgYPosition
        ? scene?.bgYPosition
        : minY + totalVerticalDistance / 2,
    ],
    bgDataPointOptions,
  );

  if (editScene) {
    setDraggedItem(bgDataPoint);
    bgDataPoint.on("drag", () => {
      // Update background data point position in y position only
      bgDataPoint.moveTo([0, bgDataPoint.Y()]);
    });

    // Update background data point y
    bgDataPoint.on("up", (e) => {
      if (getDraggedItem() !== bgDataPoint.id) return;
      setScene({
        ...scene,
        bgYPosition: bgDataPoint.Y(),
      });
    });
  }
};
