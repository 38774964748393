import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

// Store
import { useObjectStore, useScenesStore } from "../store/store";

// Services
import {
  fetchMarbleScene,
  fetchMarbleScenes,
  updateMarbleScene,
} from "../services/marbleScenes";

// Utilities
import { absoluteToRelative } from "../utilities/dataConverter";
import { buildScene } from "../utilities/buildScene";

//Constants
import {
  BACKGROUND_IMAGES,
  DEFAULT_BALL_DIAMETER,
  BALL_SCREEN_INITIAL_SIZE_PERCENTAGE,
  TRANSITION_ELEMENT_INITIAL_RADIUS,
  SCENE_STATES,
  DEFAULT_DRAGGING_FORCE_PARAMETER,
  DEFAULT_PHYSICS_OPTIONS,
  BACKGROUND_IMAGES_ENUM,
  DEFAULT_SCALE,
  FORWARD_FORCE_FUNCTiON_ENUM,
  FORWARD_FORCE_FUNCTiON_LIST,
  DEFAULT_PRESSURE_INDICATOR_COLOR,
} from "../constants/constants";

// Pages
import { Loading } from "./Loading";

// Components
import { Modal } from "../components/Modal";
import { PreviewScene } from "../components/PreviewScene";
import { Chain } from "../components/Chain";

// Prime React Components
import { Dropdown } from "primereact/dropdown";
import { ListBox } from "primereact/listbox";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { pointOptions } from "../utilities/pointUtils";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Checkbox } from "primereact/checkbox";
import { ColorPicker } from "primereact/colorpicker";

export const Scene = () => {
  // let activated = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { sceneId } = useParams();
  const { setFocusedObject } = useObjectStore();
  const scene = useScenesStore((state) => state.scene);
  const setScene = useScenesStore((state) => state.setScene);
  const [editScene, setEditScene] = useState(false);
  const [scenesOptions, setScenesOptions] = useState([]);
  const [previewScene, setPreviewScene] = useState(false);
  const [saveToIdDropdown, setSaveToIdDropdown] = useState({
    saveToId: sceneId,
    isOpen: false,
  });
  const [modal, setModal] = useState({
    show: false,
    chainIndex: 0,
    elementIndex: 0,
    element: null,
  });

  const { data, isLoading } = useQuery({
    queryKey: ["scenes", sceneId],
    queryFn: () => fetchMarbleScene(sceneId),
    select: useCallback((data) => absoluteToRelative(data), []),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const scenesQuery = useQuery({
    queryKey: ["scenes"],
    queryFn: fetchMarbleScenes,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (location.state) {
      const relativeScene = absoluteToRelative(location.state.duplicateScene);
      setScene(relativeScene);
    } else {
      setScene(data);
    }
  }, [data, location.state]);

  useEffect(() => {
    const allScenes = [
      {
        id: sceneId,
        title: "Current Scene",
      },
    ];

    if (scenesQuery.data) {
      scenesQuery.data.forEach((option) => {
        if (option.id !== sceneId)
          allScenes.push({
            id: option.id,
            title: option.data.title,
          });
      });
    }

    if (allScenes.every((scene) => scene.id !== sceneId)) {
      allScenes.unshift({
        id: sceneId,
        title: "Current Scene",
      });
    }

    setScenesOptions(allScenes);
  }, [scenesQuery.data]);

  const absoluteScene = useMemo(() => scene && buildScene(scene), [scene]);

  const updateSceneMutation = useMutation({
    mutationFn: ({ saveToId, absoluteScene }) => {
      updateMarbleScene(sceneId, {
        ...absoluteScene,
        lastEditedAt: new Date(),
      });

      if (saveToId !== sceneId) {
        fetchMarbleScene(saveToId)
          .then((saveToScene) => {
            updateMarbleScene(saveToId, {
              ...absoluteScene,
              title: saveToScene.title,
              createdAt: saveToScene.createdAt,
              state: saveToScene.state,
              lastEditedAt: new Date(),
            });
          })
          .catch((error) => console.error(error));
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["scenes", sceneId],
      });
      navigate(-1);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const getSceneTitleById = (id) => {
    const scene = scenesOptions.find((item) => item.id === id);
    return scene ? scene.title : "Title not found";
  };

  // if (data && !activated.current) {
  //   activated.current = true;
  //   setScene(data);
  // }
  if (isLoading) return <Loading />;

  return (
    <div className="relative">
      <ConfirmDialog />
      {/* Preview Scene Action Button */}
      <Button
        data-pr-tooltip="Preview"
        onClick={() => setPreviewScene(true)}
        pt={{ label: { style: { display: "none" } } }}
        className="custom-tooltip fixed right-20 top-[6.25rem] h-[3.125rem] w-[3.125rem] p-4"
        icon="pi pi-eye"
      />

      {/* Scene Modal */}
      <Dialog
        header={scene?.title}
        visible={previewScene}
        blockScroll
        onHide={() => setPreviewScene(false)}
        className={`${
          window.innerWidth > window.innerHeight
            ? "h-[90vh] w-[80vh]"
            : "h-[90vw] w-[80vw]"
        }`}
        pt={{
          content: { className: "overflow-hidden" },
        }}
      >
        <PreviewScene
          absoluteScene={absoluteScene}
          scenesOptions={scenesOptions}
          editScene={true}
          setScene={setScene}
        />
      </Dialog>

      {/* Element Modal */}
      <Dialog
        header={scene?.title}
        visible={modal.show}
        blockScroll
        onHide={() => {
          setFocusedObject({ index: null, type: "", animate: false });
          setModal({ ...modal, show: false });
        }}
        className={`${
          window.innerWidth > window.innerHeight ? "w-[120vh]" : "w-[80vw]"
        }`}
        pt={{
          content: { className: "overflow-hidden" },
        }}
      >
        <Modal
          chainIndex={modal.chainIndex}
          elementIndex={modal.elementIndex}
          elementToUpdate={modal.element}
          setModal={setModal}
          scenesOptions={scenesOptions}
        />
      </Dialog>

      {/* Scene */}
      <div className="flex w-full gap-12 px-24 py-0">
        <div className="flex w-full flex-col items-center gap-3">
          {/* Scene Header */}
          {editScene ? (
            <EditSceneSpecsTemplate setEditScene={setEditScene} />
          ) : (
            <SceneSpecsTemplate setEditScene={setEditScene} />
          )}
          {/* Scene Body */}
          <div className="flex w-full flex-col gap-3 rounded-lg border border-slate-900 p-4">
            {/* Chain Sort Buttons Tooltip */}
            <Tooltip target=".custom-tooltip" position="top" showDelay={700} />
            {/* Chains Map */}
            {scene?.chains.map((chain, chainIndex) => (
              <div
                key={chainIndex}
                className="flex flex-col gap-3 rounded-lg border border-slate-900 p-4"
              >
                <Chain chainIndex={chainIndex} setModal={setModal} />
              </div>
            ))}
            {/* Scene Action Buttons */}
            <div className="flex justify-end gap-3">
              <Button
                label="Cancel"
                severity="danger"
                type="button"
                onClick={() => navigate(-1)}
              />
              <div className="flex">
                <Button
                  label="Save changes"
                  severity="success"
                  onClick={() => {
                    const saveScene = () => {
                      updateSceneMutation.mutate({
                        saveToId: saveToIdDropdown.saveToId,
                        absoluteScene,
                      });
                    };

                    if (sceneId !== saveToIdDropdown.saveToId) {
                      confirmDialog({
                        message: (
                          <p>
                            Are you sure you want to save changes to the
                            scene&nbsp;
                            <strong>
                              {getSceneTitleById(saveToIdDropdown.saveToId)}
                            </strong>
                            ? This action will overwrite any existing data.
                          </p>
                        ),
                        header: "Confirmation",
                        className: "max-w-[95vw] sm:max-w-[50vw]",
                        icon: "pi pi-exclamation-triangle",
                        accept: saveScene,
                      });
                    } else {
                      saveScene();
                    }
                  }}
                  className="rounded-r-none shadow-none"
                />
                <div className="relative">
                  <Button
                    icon={`pi ${saveToIdDropdown.isOpen ? "pi-angle-up" : "pi-angle-down"}`}
                    severity="success"
                    onClick={() =>
                      setSaveToIdDropdown({
                        ...saveToIdDropdown,
                        isOpen: !saveToIdDropdown.isOpen,
                      })
                    }
                    className="rounded-l-none px-1 text-center shadow-none"
                  />
                  {saveToIdDropdown.isOpen ? (
                    <div className="absolute bottom-16 right-0 max-h-80 w-72 divide-y-2 overflow-y-auto rounded-md border border-slate-700 bg-white">
                      <p className="bg-slate-200 px-4 py-2 font-bold">
                        Save to Scene
                      </p>
                      <ul className="divide-y">
                        {scenesOptions.map((scene) => {
                          return (
                            <li
                              key={scene.id}
                              onClick={() =>
                                setSaveToIdDropdown({
                                  saveToId: scene.id,
                                  isOpen: false,
                                })
                              }
                              className="flex items-center justify-between gap-4 px-6 py-2 hover:bg-slate-100"
                            >
                              <span>{scene.title}</span>

                              {saveToIdDropdown.saveToId === scene.id ? (
                                <i className="pi pi-check" />
                              ) : null}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SceneSpecsTemplate = ({ setEditScene }) => {
  const scene = useScenesStore((state) => state.scene);
  const state = Object.keys(SCENE_STATES).find(
    (key) => SCENE_STATES[key].value === scene?.state,
  );
  return (
    <div className="align-center mb-2 flex min-h-24 w-full justify-between gap-3">
      <div className="flex flex-1 justify-between gap-6">
        <div>
          <div className="mb-4 flex items-center justify-between gap-4">
            <h1 className="w-fit text-5xl">{scene?.title}</h1>
            {state ? (
              <Tag
                rounded
                severity={SCENE_STATES[state].severity}
                value={SCENE_STATES[state].label}
              />
            ) : null}
          </div>
          <div className="pl-8">
            <p className="w-fit">
              Scale:{" "}
              {!isNaN(scene?.scale?.x) ? scene?.scale?.x : DEFAULT_SCALE.x} :{" "}
              {!isNaN(scene?.scale?.y) ? scene?.scale?.y : DEFAULT_SCALE.y}
            </p>
            <p className="w-fit">
              Ball Diameter:&nbsp;
              {!isNaN(scene?.ballDiameter)
                ? scene?.ballDiameter
                : DEFAULT_BALL_DIAMETER}
              &nbsp;<strong className="italic">(units)</strong>
            </p>
            <p className="w-fit">
              Ball Diameter to Screen Width Percentage:&nbsp;
              {!isNaN(scene?.ballScreenSizePercentage)
                ? scene?.ballScreenSizePercentage
                : BALL_SCREEN_INITIAL_SIZE_PERCENTAGE}
              <strong className="italic">&#37;</strong>
            </p>
            <p className="w-fit">
              Transition Element Radius:&nbsp;
              {!isNaN(scene?.transitionElementRadius)
                ? scene?.transitionElementRadius
                : TRANSITION_ELEMENT_INITIAL_RADIUS}
              &nbsp;<strong className="italic">(units)</strong>
            </p>
            <p className="w-fit">
              Dragging Force Parameter:&nbsp;
              {!isNaN(scene?.draggingForceParameter)
                ? scene?.draggingForceParameter
                : DEFAULT_DRAGGING_FORCE_PARAMETER}
              &nbsp;
              <strong className="italic">
                (N per (m/s)<sup>2</sup>)
              </strong>
            </p>

            <p className="flex w-fit items-center">
              Pressure Indicator Color:&nbsp;
              <div
                className={`h-6 w-16 rounded-lg`}
                style={{
                  backgroundColor:
                    scene?.pressureIndicatorColor ||
                    DEFAULT_PRESSURE_INDICATOR_COLOR,
                }}
              />
            </p>
            <div className="w-full py-4">
              <p className="text-xl font-bold">Physics</p>
              <p className="w-fit">
                Forward Force Function:&nbsp;
                {scene?.physics?.forwardForceFunction ||
                  FORWARD_FORCE_FUNCTiON_ENUM.DEFAULT.label}
              </p>
              <div className="flex w-full gap-4 p-2">
                <div className="flex-1">
                  <p className="text-lg font-semibold">Android</p>
                  <div className="flex w-full flex-1 flex-col gap-3">
                    {/* Ball Mass Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Ball Mass:&nbsp;
                        {!isNaN(scene?.physics?.android?.ballMass)
                          ? scene.physics?.android?.ballMass
                          : DEFAULT_PHYSICS_OPTIONS.ballMass}
                      </p>
                    </div>
                    {/* Forward Force Scaler Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Forward Force Scaler:&nbsp;
                        {!isNaN(scene?.physics?.android?.forwardForce)
                          ? scene.physics?.android?.forwardForce
                          : DEFAULT_PHYSICS_OPTIONS.forwardForce}
                      </p>
                    </div>
                    {/* Jump Force Scaler Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Jump Force Scaler:&nbsp;
                        {!isNaN(scene?.physics?.android?.jumpForce)
                          ? scene.physics?.android?.jumpForce
                          : DEFAULT_PHYSICS_OPTIONS.jumpForce}
                      </p>
                    </div>
                    {/* Jump Distance Threshold Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Jump Distance Threshold:&nbsp;
                        {!isNaN(scene?.physics?.android?.jumpDistanceThreshold)
                          ? scene.physics?.android?.jumpDistanceThreshold
                          : DEFAULT_PHYSICS_OPTIONS.jumpDistanceThreshold}
                        %
                      </p>
                    </div>
                    {/* Force Maximum Velocity Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Force Maximum Velocity:&nbsp;
                        {scene?.physics?.android?.maximumVelocity?.force
                          ? !isNaN(
                              scene?.physics?.android?.maximumVelocity
                                ?.threshold,
                            )
                            ? scene?.physics?.android?.maximumVelocity
                                ?.threshold
                            : DEFAULT_PHYSICS_OPTIONS.maximumVelocity.threshold
                          : "Disabled"}
                      </p>
                    </div>

                    {/* Background Speed Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Background Speed:&nbsp;
                        {!isNaN(scene?.physics?.android?.backgroundSpeed)
                          ? scene.physics?.android?.backgroundSpeed
                          : DEFAULT_PHYSICS_OPTIONS.backgroundSpeed}
                      </p>
                    </div>
                    {/* Background Opacity Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Background Opacity:&nbsp;
                        {!isNaN(scene?.physics?.android?.backgroundOpacity)
                          ? scene.physics?.android?.backgroundOpacity
                          : DEFAULT_PHYSICS_OPTIONS.backgroundOpacity}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <p className="text-lg font-semibold">IOS</p>
                  <div className="flex w-full flex-1 flex-col gap-3">
                    {/* Ball Mass Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Ball Mass:&nbsp;
                        {!isNaN(scene?.physics?.ios?.ballMass)
                          ? scene.physics?.ios?.ballMass
                          : DEFAULT_PHYSICS_OPTIONS.ballMass}
                      </p>
                    </div>
                    {/* Forward Force Scaler Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Forward Force Scaler:&nbsp;
                        {!isNaN(scene?.physics?.ios?.forwardForce)
                          ? scene.physics?.ios?.forwardForce
                          : DEFAULT_PHYSICS_OPTIONS.forwardForce}
                      </p>
                    </div>
                    {/* Jump Force Scaler Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Jump Force Scaler:&nbsp;
                        {!isNaN(scene?.physics?.ios?.jumpForce)
                          ? scene.physics?.ios?.jumpForce
                          : DEFAULT_PHYSICS_OPTIONS.jumpForce}
                      </p>
                    </div>
                    {/* Jump Distance Threshold Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Jump Distance Threshold:&nbsp;
                        {!isNaN(scene?.physics?.ios?.jumpDistanceThreshold)
                          ? scene.physics?.ios?.jumpDistanceThreshold
                          : DEFAULT_PHYSICS_OPTIONS.jumpDistanceThreshold}
                        %
                      </p>
                    </div>
                    {/* Force Maximum Velocity Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Force Maximum Velocity:&nbsp;
                        {scene?.physics?.ios?.maximumVelocity?.force
                          ? !isNaN(
                              scene?.physics?.ios?.maximumVelocity?.threshold,
                            )
                            ? scene?.physics?.ios?.maximumVelocity?.threshold
                            : DEFAULT_PHYSICS_OPTIONS.maximumVelocity.threshold
                          : "Disabled"}
                      </p>
                    </div>

                    {/* Background Speed Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Background Speed:&nbsp;
                        {!isNaN(scene?.physics?.ios?.backgroundSpeed)
                          ? scene.physics?.ios?.backgroundSpeed
                          : DEFAULT_PHYSICS_OPTIONS.backgroundSpeed}
                      </p>
                    </div>
                    {/* Background Opacity Parameter */}
                    <div className="flex items-center gap-3">
                      <p>
                        Background Opacity:&nbsp;
                        {!isNaN(scene?.physics?.ios?.backgroundOpacity)
                          ? scene.physics?.ios?.backgroundOpacity
                          : DEFAULT_PHYSICS_OPTIONS.backgroundOpacity}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {scene?.bgImage &&
          scene.bgImage !== BACKGROUND_IMAGES_ENUM.NONE.value && (
            <img
              src={
                BACKGROUND_IMAGES.find((img) => img.value === scene.bgImage)
                  .images[0]
              }
              alt={scene.bgImage}
              className="aspect-auto size-52 rounded-lg object-cover"
            />
          )}
      </div>
      <div>
        <Button
          onClick={() => setEditScene(true)}
          severity="info"
          label="Edit Scene"
          className="whitespace-nowrap"
        />
      </div>
    </div>
  );
};

const EditSceneSpecsTemplate = ({ setEditScene }) => {
  const scene = useScenesStore((state) => state.scene);
  const setScene = useScenesStore((state) => state.setScene);

  const defaultValues = {
    title: scene?.title || "",
    state: scene?.state || SCENE_STATES.DEVELOPMENT.value,
    scaleX: !isNaN(scene?.scale?.x) ? scene?.scale?.x : DEFAULT_SCALE.x,
    scaleY: !isNaN(scene?.scale?.y) ? scene?.scale?.y : DEFAULT_SCALE.y,
    snapToGrid: pointOptions.snapToGrid,
    ballDiameter: !isNaN(scene?.ballDiameter)
      ? scene?.ballDiameter
      : DEFAULT_BALL_DIAMETER,
    ballScreenSizePercentage: !isNaN(scene?.ballScreenSizePercentage)
      ? scene?.ballScreenSizePercentage
      : BALL_SCREEN_INITIAL_SIZE_PERCENTAGE,
    transitionElementRadius: !isNaN(scene?.transitionElementRadius)
      ? scene?.transitionElementRadius
      : TRANSITION_ELEMENT_INITIAL_RADIUS,
    bgImage: scene?.bgImage || "",
    draggingForceParameter: !isNaN(scene?.draggingForceParameter)
      ? scene?.draggingForceParameter
      : DEFAULT_DRAGGING_FORCE_PARAMETER,
    pressureIndicatorColor:
      scene?.pressureIndicatorColor || DEFAULT_PRESSURE_INDICATOR_COLOR,
    physics: {
      forwardForceFunction:
        scene?.physics?.forwardForceFunction ||
        FORWARD_FORCE_FUNCTiON_ENUM.DEFAULT.value,
      android: {
        ballMass: !isNaN(scene.physics?.android?.ballMass)
          ? scene.physics?.android?.ballMass
          : DEFAULT_PHYSICS_OPTIONS.ballMass,
        forwardForce: !isNaN(scene.physics?.android?.forwardForce)
          ? scene.physics?.android?.forwardForce
          : DEFAULT_PHYSICS_OPTIONS.forwardForce,
        jumpForce: !isNaN(scene.physics?.android?.jumpForce)
          ? scene.physics?.android?.jumpForce
          : DEFAULT_PHYSICS_OPTIONS.jumpForce,
        jumpDistanceThreshold: !isNaN(
          scene.physics?.android?.jumpDistanceThreshold,
        )
          ? scene.physics?.android?.jumpDistanceThreshold
          : DEFAULT_PHYSICS_OPTIONS.jumpDistanceThreshold,
        maximumVelocity: {
          force:
            scene.physics?.android?.maximumVelocity?.force ||
            DEFAULT_PHYSICS_OPTIONS.maximumVelocity.force,
          threshold: !isNaN(scene.physics?.android?.maximumVelocity?.threshold)
            ? scene.physics?.android?.maximumVelocity?.threshold
            : DEFAULT_PHYSICS_OPTIONS.maximumVelocity.threshold,
        },
        backgroundSpeed: !isNaN(scene.physics?.android?.backgroundSpeed)
          ? scene.physics?.android?.backgroundSpeed
          : DEFAULT_PHYSICS_OPTIONS.backgroundSpeed,
        backgroundOpacity: !isNaN(scene.physics?.android?.backgroundOpacity)
          ? scene.physics?.android?.backgroundOpacity
          : DEFAULT_PHYSICS_OPTIONS.backgroundOpacity,
      },
      ios: {
        ballMass: !isNaN(scene.physics?.ios?.ballMass)
          ? scene.physics?.ios?.ballMass
          : DEFAULT_PHYSICS_OPTIONS.ballMass,
        forwardForce: !isNaN(scene.physics?.ios?.forwardForce)
          ? scene.physics?.ios?.forwardForce
          : DEFAULT_PHYSICS_OPTIONS.forwardForce,
        jumpForce: !isNaN(scene.physics?.ios?.jumpForce)
          ? scene.physics?.ios?.jumpForce
          : DEFAULT_PHYSICS_OPTIONS.jumpForce,
        jumpDistanceThreshold: !isNaN(scene.physics?.ios?.jumpDistanceThreshold)
          ? scene.physics?.ios?.jumpDistanceThreshold
          : DEFAULT_PHYSICS_OPTIONS.jumpDistanceThreshold,
        maximumVelocity: {
          force:
            scene.physics?.ios?.maximumVelocity?.force ||
            DEFAULT_PHYSICS_OPTIONS.maximumVelocity.force,
          threshold: !isNaN(scene.physics?.ios?.maximumVelocity?.threshold)
            ? scene.physics?.ios?.maximumVelocity?.threshold
            : DEFAULT_PHYSICS_OPTIONS.maximumVelocity.threshold,
        },
        backgroundSpeed: !isNaN(scene.physics?.ios?.backgroundSpeed)
          ? scene.physics?.ios?.backgroundSpeed
          : DEFAULT_PHYSICS_OPTIONS.backgroundSpeed,
        backgroundOpacity: !isNaN(scene.physics?.ios?.backgroundOpacity)
          ? scene.physics?.ios?.backgroundOpacity
          : DEFAULT_PHYSICS_OPTIONS.backgroundOpacity,
      },
    },
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  const handleEditScene = (data) => {
    setScene({
      ...scene,
      title: data.title || scene?.id,
      state: data.state || scene.state,
      scale: {
        x: Number(data.scaleX),
        y: Number(data.scaleY),
      },
      ballDiameter: Number(data.ballDiameter),
      ballScreenSizePercentage: Number(data.ballScreenSizePercentage),
      transitionElementRadius: Number(data.transitionElementRadius),
      bgImage: data.bgImage || "",
      draggingForceParameter: Number(data?.draggingForceParameter),
      pressureIndicatorColor:
        data?.pressureIndicatorColor || DEFAULT_PRESSURE_INDICATOR_COLOR,
      physics: {
        forwardForceFunction:
          data?.physics?.forwardForceFunction ||
          FORWARD_FORCE_FUNCTiON_ENUM.DEFAULT.value,
        android: {
          ballMass: Number(data.physics.android.ballMass),
          forwardForce: Number(data.physics?.android?.forwardForce),
          jumpForce: Number(data.physics?.android?.jumpForce),
          jumpDistanceThreshold: Number(
            data.physics?.android?.jumpDistanceThreshold,
          ),
          maximumVelocity: {
            force:
              data.physics?.android?.maximumVelocity?.force ||
              DEFAULT_PHYSICS_OPTIONS.maximumVelocity.force,
            threshold: Number(
              data.physics?.android?.maximumVelocity?.threshold,
            ),
          },
          backgroundSpeed: Number(data.physics?.android?.backgroundSpeed),
          backgroundOpacity: Number(data.physics?.android?.backgroundOpacity),
        },
        ios: {
          ballMass: Number(data.physics.ios.ballMass),
          forwardForce: Number(data.physics?.ios?.forwardForce),
          jumpForce: Number(data.physics?.ios?.jumpForce),
          jumpDistanceThreshold: Number(
            data.physics?.ios?.jumpDistanceThreshold,
          ),
          maximumVelocity: {
            force:
              data.physics?.ios?.maximumVelocity?.force ||
              DEFAULT_PHYSICS_OPTIONS.maximumVelocity.force,
            threshold: Number(data.physics?.ios?.maximumVelocity?.threshold),
          },
          backgroundSpeed: Number(data.physics?.ios?.backgroundSpeed),
          backgroundOpacity: Number(data.physics?.ios?.backgroundOpacity),
        },
      },
    });
    reset();
    setEditScene(false);
  };
  return (
    <form
      onSubmit={handleSubmit(handleEditScene)}
      className="mb-2 flex w-full flex-col gap-3"
    >
      {/* Title */}
      <div className="flex items-center gap-3">
        <p>Title:&nbsp;</p>
        <InputText
          {...register("title", { required: "Title is required" })}
          className="rounded-lg border border-slate-900 p-2"
        />
        {errors?.title && (
          <p className="text-red-500">{errors.title.message}</p>
        )}
      </div>
      {/* State */}
      <div className="flex items-center gap-3">
        <p>State:&nbsp;</p>
        <Controller
          control={control}
          name="state"
          render={({ field: { onChange, onBlur, value } }) => (
            <Dropdown
              value={value}
              onChange={onChange}
              options={Object.values(SCENE_STATES)}
              optionValue="value"
              optionLabel="label"
              placeholder="Select Scene State"
              className="rounded-lg border border-slate-900"
            />
          )}
        />

        {errors?.state && (
          <p className="text-red-500">{errors.state.message}</p>
        )}
      </div>
      {/* Scale */}
      <div className="flex items-center gap-3">
        <p>Scale:&nbsp;</p>
        {/* X Scale */}
        <div className="flex items-center gap-3">
          <InputText
            {...register("scaleX", {
              required: "Horizontal Scale is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.scaleX && (
            <p className="text-red-500">{errors.scaleX.message}</p>
          )}
        </div>
        {/* Y Scale */}
        <div className="flex items-center gap-3">
          <span>:</span>
          <InputText
            {...register("scaleY", {
              required: "Vertical Scale is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.scaleY && (
            <p className="text-red-500">{errors.scaleY.message}</p>
          )}
        </div>
      </div>
      {/* Ball Diameter */}
      <div className="flex items-center gap-3">
        <p>Ball Diameter:&nbsp;</p>
        <div className="flex items-center gap-3">
          <InputText
            {...register("ballDiameter", {
              required: "Ball Diameter is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.ballDiameter && (
            <p className="text-red-500">{errors.ballDiameter.message}</p>
          )}
        </div>
      </div>
      {/* Ball Screen Size Percentage */}
      <div className="flex items-center gap-3">
        <p>Ball Diameter to Screen Width Percentage:&nbsp;</p>
        <div className="flex items-center gap-3">
          <InputText
            {...register("ballScreenSizePercentage", {
              required: "Ball Screen Size Percentage is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.ballScreenSizePercentage && (
            <p className="text-red-500">
              {errors.ballScreenSizePercentage.message}
            </p>
          )}
        </div>
      </div>
      {/* Transition Element Radius */}
      <div className="flex items-center gap-3">
        <p>Transition Element Radius:&nbsp;</p>
        <div className="flex items-center gap-3">
          <InputText
            {...register("transitionElementRadius", {
              required: "Transition Element Radius is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.transitionElementRadius && (
            <p className="text-red-500">
              {errors.transitionElementRadius.message}
            </p>
          )}
        </div>
      </div>
      {/* Dragging Force Parameter */}
      <div className="flex items-center gap-3">
        <p>Dragging Force Parameter:&nbsp;</p>
        <div className="flex items-center gap-3">
          <InputText
            {...register("draggingForceParameter", {
              required: "Dragging Force Parameter is required",
              pattern: {
                value: /^\d*\.?\d*$/,
                message: "Please enter valid number",
              },
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.draggingForceParameter && (
            <p className="text-red-500">
              {errors.draggingForceParameter.message}
            </p>
          )}
        </div>
      </div>
      {/* Pressure Indicator Color Parameter */}
      <div className="flex items-center gap-3">
        <p>Pressure Indicator Color:&nbsp;</p>
        <Controller
          name={`pressureIndicatorColor`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <ColorPicker
              name="pressureIndicatorColor"
              value={field.value}
              onChange={(e) => {
                field.onChange({
                  target: {
                    name: e.target.name,
                    value: `#${e.target.value}`,
                  },
                });
              }}
              pt={{
                input: { className: "w-full p-6" },
              }}
              className="col-span-6"
            />
          )}
        />
      </div>
      {/* Physics */}
      <div className="w-full">
        <p className="text-xl font-bold">Physics</p>
        {/* Dragging Force Parameter */}
        <div className="flex items-center gap-3">
          <p>Forward Force Function:&nbsp;</p>
          <div className="flex gap-3">
            <Controller
              name={`physics.forwardForceFunction`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Dropdown
                  {...field}
                  options={FORWARD_FORCE_FUNCTiON_LIST}
                  optionValue="value"
                  optionLabel="label"
                  placeholder="Select Forward Force Function"
                  className="rounded-lg border border-slate-900"
                />
              )}
            />
          </div>
        </div>
        <div className="flex w-full gap-4 p-4">
          <div className="flex-1">
            <p className="text-lg font-semibold">Android</p>
            <div className="flex w-full flex-1 flex-col gap-3">
              {/* Ball Mass Parameter */}
              <div className="flex items-center gap-3">
                <p>Ball Mass:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.ballMass", {
                      required: "Ball mass is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.ballMass && (
                    <p className="text-red-500">
                      {errors.physics.android.ballMass.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Forward Force Scaler Parameter */}
              <div className="flex items-center gap-3">
                <p>Forward Force Scaler:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.forwardForce", {
                      required: "Forward force scaler is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.forwardForce && (
                    <p className="text-red-500">
                      {errors.physics.android.forwardForce.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Jump Force Scaler Parameter */}
              <div className="flex items-center gap-3">
                <p>Jump Force Scaler:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.jumpForce", {
                      required: "Jump force scaler is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.jumpForce && (
                    <p className="text-red-500">
                      {errors.physics.android.jumpForce.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Jump Distance Threshold Parameter */}
              <div className="flex items-center gap-3">
                <p>Jump Distance Threshold:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.jumpDistanceThreshold", {
                      required: "Jump distance threshold is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.jumpDistanceThreshold && (
                    <p className="text-red-500">
                      {errors.physics.android.jumpDistanceThreshold.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Force Maximum Velocity Parameter */}
              <div className="flex items-center gap-3">
                <p>Force Maximum Velocity:&nbsp;</p>

                <div className="flex items-center gap-3">
                  <Controller
                    name={`physics.android.maximumVelocity.force`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />

                  {errors?.physics?.android?.maximumVelocity?.force && (
                    <p className="text-red-500">
                      {errors.physics.android.maximumVelocity.force.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Maximum Velocity Threshold Parameter */}
              <div className="flex items-center gap-3">
                <p>Maximum Velocity Threshold:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.maximumVelocity.threshold", {
                      required: "Maximum velocity threshold is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    disabled={!watch("physics.android.maximumVelocity.force")}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.maximumVelocity?.threshold && (
                    <p className="text-red-500">
                      {errors.physics.android.maximumVelocity.threshold.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Background Speed Parameter */}
              <div className="flex items-center gap-3">
                <p>Background Speed:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.backgroundSpeed", {
                      required: "Background speed is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.backgroundSpeed && (
                    <p className="text-red-500">
                      {errors.physics.android.backgroundSpeed.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Background Opacity Parameter */}
              <div className="flex items-center gap-3">
                <p>Background Opacity:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.android.backgroundOpacity", {
                      required: "Background opacity is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.android?.backgroundOpacity && (
                    <p className="text-red-500">
                      {errors.physics.android.backgroundOpacity.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <p className="text-lg font-semibold">IOS</p>
            <div className="flex w-full flex-1 flex-col gap-3">
              {/* Ball Mass Parameter */}
              <div className="flex items-center gap-3">
                <p>Ball Mass:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.ballMass", {
                      required: "Ball mass is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.ballMass && (
                    <p className="text-red-500">
                      {errors.physics.ios.ballMass.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Forward Force Scaler Parameter */}
              <div className="flex items-center gap-3">
                <p>Forward Force Scaler:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.forwardForce", {
                      required: "Forward force scaler is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.forwardForce && (
                    <p className="text-red-500">
                      {errors.physics.ios.forwardForce.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Jump Force Scaler Parameter */}
              <div className="flex items-center gap-3">
                <p>Jump Force Scaler:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.jumpForce", {
                      required: "Jump force scaler is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.jumpForce && (
                    <p className="text-red-500">
                      {errors.physics.ios.jumpForce.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Jump Distance Threshold Parameter */}
              <div className="flex items-center gap-3">
                <p>Jump Distance Threshold:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.jumpDistanceThreshold", {
                      required: "Jump distance threshold is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.jumpDistanceThreshold && (
                    <p className="text-red-500">
                      {errors.physics.ios.jumpDistanceThreshold.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Force Maximum Velocity Parameter */}
              <div className="flex items-center gap-3">
                <p>Force Maximum Velocity:&nbsp;</p>

                <div className="flex items-center gap-3">
                  <Controller
                    name={`physics.ios.maximumVelocity.force`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />

                  {errors?.physics?.ios?.maximumVelocity?.force && (
                    <p className="text-red-500">
                      {errors.physics.ios.maximumVelocity.force.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Maximum Velocity Threshold Parameter */}
              <div className="flex items-center gap-3">
                <p>Maximum Velocity Threshold:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.maximumVelocity.threshold", {
                      required: "Maximum velocity threshold is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    disabled={!watch("physics.ios.maximumVelocity.force")}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.maximumVelocity?.threshold && (
                    <p className="text-red-500">
                      {errors.physics.ios.maximumVelocity.threshold.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Background Speed Parameter */}
              <div className="flex items-center gap-3">
                <p>Background Speed:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.backgroundSpeed", {
                      required: "Background speed is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.backgroundSpeed && (
                    <p className="text-red-500">
                      {errors.physics.ios.backgroundSpeed.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Background Opacity Parameter */}
              <div className="flex items-center gap-3">
                <p>Background Opacity:&nbsp;</p>
                <div className="flex items-center gap-3">
                  <InputText
                    {...register("physics.ios.backgroundOpacity", {
                      required: "Background opacity is required",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Please enter valid number",
                      },
                    })}
                    className="w-20 rounded-lg border border-slate-900 p-1"
                  />
                  {errors?.physics?.ios?.backgroundOpacity && (
                    <p className="text-red-500">
                      {errors.physics.ios.backgroundOpacity.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Background List */}
      <div className="flex items-center gap-3">
        <ListBox
          {...register("bgImage", {
            required: false,
          })}
          options={BACKGROUND_IMAGES}
          itemTemplate={(option) => <BgImageListTemplate option={option} />}
          className="w-full p-4"
          pt={{
            list: { className: "flex items-center gap-3" },
            item: { className: "shrink-0 w-64 rounded-lg overflow-x-auto" },
          }}
        />
      </div>
      <div className="flex gap-3 self-end">
        <Button
          label="Cancel"
          severity="danger"
          type="button"
          onClick={() => {
            reset({
              title: scene?.title,
              scaleX: scene?.scale?.x,
              scaleY: scene?.scale?.y,
              bgImage: scene?.bgImage,
            });
            setEditScene(false);
          }}
        />
        <Button severity="success" label="Confirm" />
      </div>
    </form>
  );
};

const BgImageListTemplate = ({ option }) => {
  return (
    <div className="flex w-full flex-col items-center gap-3">
      <img
        src={option.images[0]}
        alt={option.value}
        className="h-40 w-40 rounded-lg object-cover"
      />
      <p className="text-center">{option.value}</p>
    </div>
  );
};
