// Background Images
import NoneImage from "../images/background/None.png";
import ForestImage from "../images/background/Forest.png";
import GalaxyImage from "../images/background/Galaxy.png";
import SnowImage from "../images/background/Snow.png";

export const DIMENSIONS_FACTOR_OF_SAFETY = 1;

export const INITIAL_BOARD_DIMENSION = { x: 60, y: 60 };

export const RIGHT_CLICK_BUTTON_CODE = 2;

export const CURVE_TYPES = [1, 2, 7];

export const TRANSITION_ELEMENT_INITIAL_RADIUS = 10;
export const DEFAULT_BALL_DIAMETER = 10;
export const BALL_SCREEN_INITIAL_SIZE_PERCENTAGE = 10;
export const DEFAULT_DRAGGING_FORCE_PARAMETER = 0;
export const DEFAULT_COLOR = "#000000";
export const DEFAULT_PRESSURE_INDICATOR_COLOR = "#ccf0ff";
export const DEFAULT_FRICTION = 0.1;
export const DEFAULT_RESTITUTION = 0;
export const DEFAULT_LINE_WIDTH = 4;
export const DEFAULT_BG_Y_Position = 0;
export const DEFAULT_SCALE = { x: 1, y: 1 };
export const DEFAULT_START_POINT = { x: 0, y: 0 };
export const DEFAULT_BALL_START_POINT = { x: 10, y: 10 };

export const BACKGROUND_IMAGES_ENUM = {
  NONE: { value: "None", images: [NoneImage] },
  FOREST: { value: "Forest", images: [ForestImage] },
  GALAXY: { value: "Galaxy", images: [GalaxyImage] },
  SNOW: { value: "Snow", images: [SnowImage] },
};
export const BACKGROUND_IMAGES = [
  BACKGROUND_IMAGES_ENUM.NONE,
  BACKGROUND_IMAGES_ENUM.FOREST,
  BACKGROUND_IMAGES_ENUM.GALAXY,
  BACKGROUND_IMAGES_ENUM.SNOW,
];

export const SCENE_STATES = {
  DEVELOPMENT: {
    label: "Development",
    value: "development",
    severity: "info",
  },
  TESTING: { label: "Testing", value: "testing", severity: "warning" },
  PUBLISHED: { label: "Published", value: "published", severity: "success" },
};

export const FORWARD_FORCE_FUNCTiON_ENUM = {
  DEFAULT: { label: "Default ( Linear )", value: "linear" },
  ALTERNATE_1: { label: "Alternate 1 ( Square Root )", value: "squareRoot" },
  ALTERNATE_2: { label: "Alternate 2 ( Sine )", value: "sine" },
};
export const FORWARD_FORCE_FUNCTiON_LIST = [
  FORWARD_FORCE_FUNCTiON_ENUM.DEFAULT,
  FORWARD_FORCE_FUNCTiON_ENUM.ALTERNATE_1,
  FORWARD_FORCE_FUNCTiON_ENUM.ALTERNATE_2,
];

export const DEFAULT_PHYSICS_OPTIONS = {
  ballMass: 1,
  forwardForce: 1,
  jumpForce: 250,
  jumpDistanceThreshold: 75,
  backgroundSpeed: 0.05,
  backgroundOpacity: 0.2,
  maximumVelocity: {
    force: false,
    threshold: 0,
  },
};

export const NEW_SCENE = {
  title: "",
  scale: DEFAULT_SCALE,
  bgImage: BACKGROUND_IMAGES_ENUM.NONE.value,
  bgYPosition: DEFAULT_BG_Y_Position,
  startPoint: DEFAULT_BALL_START_POINT,
  transitionElementRadius: TRANSITION_ELEMENT_INITIAL_RADIUS,
  ballDiameter: DEFAULT_BALL_DIAMETER,
  ballScreenSizePercentage: BALL_SCREEN_INITIAL_SIZE_PERCENTAGE,
  draggingForceParameter: DEFAULT_DRAGGING_FORCE_PARAMETER,
  chains: [{ startPoint: DEFAULT_START_POINT, elements: [] }],
  createdAt: new Date(),
  lastEditedAt: new Date(),
  state: SCENE_STATES.DEVELOPMENT.value,
  pressureIndicatorColor: DEFAULT_PRESSURE_INDICATOR_COLOR,
  physics: {
    forwardForceFunction: FORWARD_FORCE_FUNCTiON_ENUM.DEFAULT.value,
    android: { ...DEFAULT_PHYSICS_OPTIONS },
    ios: { ...DEFAULT_PHYSICS_OPTIONS },
  },
};

export const NEW_CHAIN = {
  startPoint: DEFAULT_START_POINT,
  elements: [],
};

export const LINE_TYPES = {
  FLAT_LINE: { label: "Flat Line", value: 0 },
  DOWNHILL_CURVE: { label: "Downhill Curve", value: 1 },
  UPHILL_CURVE: { label: "Uphill Curve", value: 2 },
  BUMP_CURVE: { label: "Bump Curve", value: 3 },
  GAP: { label: "Gap", value: 4 },
  FLAT_CURVE: { label: "Flat Curve", value: 7 },
};

export const FALLING_CONDITIONS = {
  BALL_CONTACT: { label: "Ball Contact", value: "ballContact" },
  AUTOMATIC_TIMER: { label: "Automatic Timer", value: "automaticTimer" },
  BOTH: { label: "Ball Contact & Automatic Timer", value: "both" },
};

export const DEFAULT_FALLING_ELEMENT_DATA = {
  fallCondition: FALLING_CONDITIONS.BALL_CONTACT.value,
  fallDelay: 1,
  regenerate: false,
  regenerateDelay: 1,
};

export const DEFAULT_ELEMENT_DATA = {
  color: DEFAULT_COLOR,
  friction: DEFAULT_FRICTION,
  restitution: DEFAULT_RESTITUTION,
  lineWidth: DEFAULT_LINE_WIDTH,
  isFalling: false,
  targets: [],
  obstacles: [],
};

export const DEFAULT_FLAT_LINE_DATA = {
  type: LINE_TYPES.FLAT_LINE.value,
  points: {
    startPoint: DEFAULT_START_POINT,
    endPoint: { x: 100, y: 0 },
  },
  rippleDensity: 0,
  rippleHeight: 0,
};

export const DEFAULT_GAP_DATA = {
  type: LINE_TYPES.GAP.value,
  points: {
    startPoint: DEFAULT_START_POINT,
    endPoint: { x: 20, y: 0 },
  },
};

export const DEFAULT_DOWNHILL_CURVE_DATA = {
  type: LINE_TYPES.DOWNHILL_CURVE.value,
  points: {
    startPoint: DEFAULT_START_POINT,
    endPoint: { x: 100, y: -10 },
    firstControlPoint: { x: 50, y: 0 },
    secondControlPoint: { x: 50, y: -10 },
  },
};

export const DEFAULT_UPHILL_CURVE_DATA = {
  type: LINE_TYPES.UPHILL_CURVE.value,
  points: {
    startPoint: DEFAULT_START_POINT,
    endPoint: { x: 100, y: 10 },
    firstControlPoint: { x: 50, y: 0 },
    secondControlPoint: { x: 50, y: 10 },
  },
};

export const DEFAULT_FLAT_CURVE_DATA = {
  type: LINE_TYPES.FLAT_CURVE.value,
  points: {
    startPoint: DEFAULT_START_POINT,
    endPoint: { x: 100, y: 0 },
    firstControlPoint: { x: 33, y: 0 },
    secondControlPoint: { x: 67, y: 0 },
  },
};

export const DEFAULT_BUMP_CURVE_DATA = {
  type: LINE_TYPES.BUMP_CURVE.value,
  points: {
    startPoint: DEFAULT_START_POINT,
    endPoint: { x: 30, y: 0 },
    firstControlPoint: { x: 15, y: 17 },
  },
};

export const OBJECT_TYPES = {
  TARGET: "target",
  OBSTACLE: "obstacle",
};

export const TARGET_TYPES = {
  COIN: { label: "Coin", value: "coin" },
  STAR: { label: "Star", value: "star" },
  FLAG: { label: "Flag", value: "flag" },
};

export const OBSTACLE_TYPES = {
  ELLIPSE: { label: "Ellipse", value: "ellipse" },
  RECTANGLE: { label: "Rectangle", value: "rectangle" },
  TRIANGLE: { label: "Triangle", value: "triangle" },
  CURVE: { label: "Curve", value: "curve" },
};

export const ANIMATION_TYPES = {
  NONE: "none",
  ROTATION: "rotation",
  SINUSOIDAL: "sinusoidal",
};

export const DEFAULT_OBJECT_DATA = {
  objectPosition: { x: 30, y: 20 },
};

export const DEFAULT_TARGET_DATA = {
  type: TARGET_TYPES.COIN.value,
};

export const DEFAULT_TARGET_POINT_DATA = {
  fill: "#c9c204",
  value: 1,
  size: DEFAULT_BALL_DIAMETER,
};

export const DEFAULT_TARGET_FLAG_DATA = {
  fill: "#000000",
  nextScene: "",
  size: DEFAULT_BALL_DIAMETER * 1.5,
};

export const DEFAULT_OBSTACLE_DATA = {
  type: OBSTACLE_TYPES.ELLIPSE.value,
  outline: "#000000",
  lineWidth: 4,
  friction: 0.01,
  restitution: 0,
  animationType: ANIMATION_TYPES.NONE,
};

export const DEFAULT_OBSTACLE_OBJECT_DATA = {
  fill: "#000000",
  width: 20,
  height: 20,
  rotation: 0,
};

export const DEFAULT_OBSTACLE_CURVE_DATA = {
  curvePoints: {
    startPoint: { x: 20, y: 20 },
    firstControlPoint: { x: 27.33, y: 20 },
    secondControlPoint: { x: 34.66, y: 20 },
    endPoint: { x: 40, y: 20 },
  },
};

export const DEFAULT_ANIMATION_DATA = {
  speed: 0,
  motionPoint: { x: 0, y: 0 },
  distance: { x: 0, y: 0 },
};

export const START_POINT_SVG = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="red" xmlns="http://www.w3.org/2000/svg">
    <circle r="12" cx="12" cy="12" />
  </svg>
  `;

export const imageSVG = {
  star: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_155_7542)">
        <path d="M6.74157 5.55789L10.0974 0.978947C10.3371 0.642105 10.6217 0.394737 10.9513 0.236842C11.2809 0.0789474 11.6255 0 11.985 0C12.3446 0 12.6891 0.0789474 13.0187 0.236842C13.3483 0.394737 13.633 0.642105 13.8727 0.978947L17.2285 5.55789L22.3221 7.35789C22.8414 7.52632 23.2509 7.83684 23.5506 8.28947C23.8502 8.74211 24 9.24211 24 9.78947C24 10.0421 23.965 10.2947 23.8951 10.5474C23.8252 10.8 23.7104 11.0421 23.5506 11.2737L20.2547 16.2L20.3745 21.3789C20.3945 22.1158 20.1648 22.7368 19.6854 23.2421C19.206 23.7474 18.6467 24 18.0075 24C17.9675 24 17.7478 23.9684 17.3483 23.9053L11.985 22.3263L6.62172 23.9053C6.52185 23.9474 6.41199 23.9737 6.29213 23.9842C6.17228 23.9947 6.06242 24 5.96255 24C5.32335 24 4.76404 23.7474 4.28464 23.2421C3.80524 22.7368 3.57553 22.1158 3.59551 21.3789L3.71536 16.1684L0.449438 11.2737C0.289638 11.0421 0.174782 10.8 0.104869 10.5474C0.0349563 10.2947 0 10.0421 0 9.78947C0 9.26316 0.144819 8.77368 0.434457 8.32105C0.724095 7.86842 1.12859 7.54737 1.64794 7.35789L6.74157 5.55789Z" style="fill:%%FILL_COLOR%%"/>
      </g>
      <defs>
        <clipPath id="clip0_155_7542">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  `,
  coin: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_155_7530)">
        <path d="M10.92 20.4H13.02V18.9C14.02 18.72 14.88 18.33 15.6 17.73C16.32 17.13 16.68 16.24 16.68 15.06C16.68 14.22 16.44 13.45 15.96 12.75C15.48 12.05 14.52 11.44 13.08 10.92C11.88 10.52 11.05 10.17 10.59 9.87C10.13 9.57 9.9 9.16 9.9 8.64C9.9 8.12 10.085 7.71 10.455 7.41C10.825 7.11 11.36 6.96 12.06 6.96C12.7 6.96 13.2 7.115 13.56 7.425C13.92 7.735 14.18 8.12 14.34 8.58L16.26 7.8C16.04 7.1 15.635 6.49 15.045 5.97C14.455 5.45 13.8 5.16 13.08 5.1V3.6H10.98V5.1C9.98 5.32 9.2 5.76 8.64 6.42C8.08 7.08 7.8 7.82 7.8 8.64C7.8 9.58 8.075 10.34 8.625 10.92C9.175 11.5 10.04 12 11.22 12.42C12.48 12.88 13.355 13.29 13.845 13.65C14.335 14.01 14.58 14.48 14.58 15.06C14.58 15.72 14.345 16.205 13.875 16.515C13.405 16.825 12.84 16.98 12.18 16.98C11.52 16.98 10.935 16.775 10.425 16.365C9.915 15.955 9.54 15.34 9.3 14.52L7.32 15.3C7.6 16.26 8.035 17.035 8.625 17.625C9.215 18.215 9.98 18.62 10.92 18.84V20.4ZM12 24C10.34 24 8.78 23.685 7.32 23.055C5.86 22.425 4.59 21.57 3.51 20.49C2.43 19.41 1.575 18.14 0.945 16.68C0.315 15.22 0 13.66 0 12C0 10.34 0.315 8.78 0.945 7.32C1.575 5.86 2.43 4.59 3.51 3.51C4.59 2.43 5.86 1.575 7.32 0.945C8.78 0.315 10.34 0 12 0C13.66 0 15.22 0.315 16.68 0.945C18.14 1.575 19.41 2.43 20.49 3.51C21.57 4.59 22.425 5.86 23.055 7.32C23.685 8.78 24 10.34 24 12C24 13.66 23.685 15.22 23.055 16.68C22.425 18.14 21.57 19.41 20.49 20.49C19.41 21.57 18.14 22.425 16.68 23.055C15.22 23.685 13.66 24 12 24Z"  style="fill:%%FILL_COLOR%%"/>
      </g>
      <defs>
        <clipPath id="clip0_155_7530">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  `,
  flag: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 3H10.5V0H7.5V3ZM13.5 3V0H16.5V3H13.5ZM7.5 15V12H10.5V15H7.5ZM19.5 9V6H22.5V9H19.5ZM19.5 15V12H22.5V15H19.5ZM13.5 15V12H16.5V15H13.5ZM19.5 3V0H22.5V3H19.5ZM10.5 6V3H13.5V6H10.5ZM1.5 24V0H4.5V3H7.5V6H4.5V9H7.5V12H4.5V24H1.5ZM16.5 12V9H19.5V12H16.5ZM10.5 12V9H13.5V12H10.5ZM7.5 9V6H10.5V9H7.5ZM13.5 9V6H16.5V9H13.5ZM16.5 6V3H19.5V6H16.5Z" style="fill:%%FILL_COLOR%%"/>
    </svg>
  `,
  circle: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <ellipse rx="%%WIDTH%%" ry="%%HEIGHT%%" cx="50" cy="50" fill="%%FILL_COLOR%%" stroke="%%STROKE_COLOR%%" stroke-width="%%STROKE_WIDTH%%" stroke-linecap="round" />
    </svg>  
  `,
  square: `
    <svg xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" style="fill:%%FILL_COLOR%%;stroke-width:%%STROKE_WIDTH%%;stroke:%%STROKE_COLOR%%" />
    </svg>
  `,
  triangle: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <polygon points="50,%%Y0%% %%X1%%,%%Y1%% %%X2%%,%%Y2%%" fill="%%FILL_COLOR%%" stroke="%%STROKE_COLOR%%" stroke-width="%%STROKE_WIDTH%%" />
    </svg>
  `,
};
